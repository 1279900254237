'use client';

import { Button } from '@/components/server';
import { ROUTES } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';

import styles from './HomeButton.module.scss';

export const HomeButton = () => {
  const router = useCustomRouter();

  return (
    <Button
      className={styles['home-button']}
      onClick={() => router.push(ROUTES.HOME.pathname)}
    >
      홈으로 이동하기
    </Button>
  );
};
